import React, { useEffect, useRef, useState } from 'react';
import { Message } from '../types';
import styles from './Chat.module.css';

const botName: string = '';
const startTxt = 'Unesite naziv svog grada ili opštine i dobićete podatke o visini prosečne neto zarade, prosečne penzije, ulaganju na osnovu regionalnog razvoja i druge podatke. Izvori javnih podataka Zavod za statistiku Republike Srbije i Agencija za privredne registre';

const Chat: React.FC = () => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [newMessage, setNewMessage] = useState<string>('');
  const [threadId, setThreadId] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement>(null); // Ref for the end of messages

  const sendMessage = async () => {
    // Mock send message functionality
    setMessages(prevMessages => [...prevMessages, { content: `You: ${newMessage}` }]);
    setNewMessage('');
    await fetchMessages(newMessage);
  };

  const fetchMessages = async (content: string) => {
    setIsLoading(true);
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/message`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ content, threadId }), // Send 'content' in the request body
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json() as Message;
        if (data.threadId) {
            setThreadId(data.threadId);
        }
        setMessages(prevMessages => [...prevMessages, { content: `${botName} ${data.content}` }]);
    } catch (error) {
        console.error('Error fetching messages:', error);
    } finally {
        setIsLoading(false);
    }
  };

  const renderMessage = (message: string) => {
    return message.split('\n').map((line, index) => (
        <p key={index}>
            {line}
        </p>
    ));
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
        event.preventDefault(); // Prevent default to avoid any undesired behavior like form submission
        sendMessage();
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    const fetchMessages = async () => {
      // Simulate a fetch call
      setMessages([{ content: startTxt }]);
      // setIsLoading(true);
      // setTimeout(() => {
      //     setIsLoading(false);
      // }, 5000); // Simulate loading for 2 seconds
  };
    fetchMessages();
  }, []);

  useEffect(() => {
    scrollToBottom(); // Scroll to bottom every time messages change
}, [messages]);

  return (
    <div>
    <header className={styles.header}>
      #SrbijaNeSmeDaNESTANE
    </header>                
    <div className={styles.container}>
      <div className={styles.messages}>      
        <div className={styles.messages}>
            {messages.map((message, index) => (
                <div key={index} className={styles.message}>{renderMessage(message.content)}</div>
            ))}
        </div>  
        <div ref={messagesEndRef} /> {/* Invisible element at the end of messages */}    
      </div>
      <div className={styles.inputSection}>
      {isLoading ? (
          <div className={styles.loader}></div> // Loader displayed while fetching
      ) : (
        <>
          <input
            type="text"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            className={styles.input}
            onKeyDown={handleKeyDown}
          />
          <button onClick={sendMessage} className={styles.button}>
            Send
          </button>
        </>
      )}
      </div>
    </div>
    </div>
  );
};

export default Chat;
